import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import l from '../localization/lang';
import AddExtract from './AddExtract';
import Game from './Game';
import Room from './Room';
import AdminPanel from './AdminPanel';
import '../css/App.css';


class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: 'en',
            strings: l.strings[0]['en']
        }
        this.joinRoom = this.joinRoom.bind(this);
        this.changeLocale = this.changeLocale.bind(this);
    }

    joinRoom(roomId, username) {
        console.log('room joined')
        this.setState({ roomId: roomId, username: username });
        // this.keepAlive();
    }

    componentDidMount() {

        let languageCode = window.localStorage.getItem('locale');

        if (languageCode === null) {
            const userLang = navigator.language || navigator.userLanguage;
            languageCode = userLang.substr(0, 2);
        }

        let authorizedLanguages = [];
        for (let item in l.strings[0]) {
            authorizedLanguages.push(item)
        }

        if (!authorizedLanguages.includes(languageCode)) {
            languageCode = 'en';
        }

        let serverUrl = 'http://localhost:5000/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:5000/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'

        this.setState({ lang: languageCode, strings: l.strings[0][languageCode], serverUrl: serverUrl })
    }

    changeLocale(value) {
        this.setState({ lang: value, strings: l.strings[0][value] })
        window.localStorage.setItem('locale', value);
    }

    // keepAlive() {
    //     console.log('keep')
    //     // const options = {
    //     //     method: 'GET'
    //     // }
    //     // fetch(this.state.serverUrl + 'k', options)
    //     //     .then(res => res.json().then(json => {
    //     //         if (json.message === 'success') {
    //     //             console.log('its alive')
    //     //         }
    //     //     }))
    //     //     .catch(err => console.log(err));

    //     setTimeout(this.keepAlive, 1000 * 10) // Toutes les deux minutes, call le serveur en get pour pas qu'il s'endorme ?
    // }

    render() {
        return (
            <div id="App">
                <Router>
                    <Switch>
                        <Route exact path='/'>
                            <Game joinRoom={this.joinRoom} strings={this.state.strings} changeLocale={this.changeLocale} />
                        </Route>
                        <Route path='/add' component={AddExtract} strings={this.state.strings} />
                        <Route path='/admin'>
                            <AdminPanel strings={this.state.strings} />
                        </Route>
                        <Route path='/🔒'>
                            <AdminPanel strings={this.state.strings} />
                        </Route>
                        <Route path='/%f0%9f%94%92'>
                            <AdminPanel strings={this.state.strings} />
                        </Route>
                        <Route path='/r/:id'>
                            <Room username={this.state.username} strings={this.state.strings} serverUrl={this.state.serverUrl} />
                        </Route>

                        <Redirect to="/" />
                    </Switch>
                </Router>
                <Helmet>
                    <title>{this.state.strings['meta_title_main']}</title>
                    <meta name="description" content={this.state.strings['meta_description']} />
                    <meta name="theme-color" content="#7c40a1" />
                </Helmet>
            </div>
        );
    }
}

export default App;
