export default {
    strings: [
        {
            en: {
                language_code: 'en',
                join_createPrivate: 'Create Private Room',
                join_joinRoom: 'Play!',
                join_joinPrivateRoom: 'Join Game!',
                join_joinRoomX: 'Join game ',
                join_enterName: 'Enter your name',
                join_players: 'players',
                error_usernameTooLong: 'Username must be less than 20 characters.',
                error_usernameRequired: 'You must choose a name.',
                about_howTo: 'How to play?',
                about_description_1: 'is a general knowledge game. The players have to find a word using its definition before the time is over. The first player to find earns 5 points, the second 3 points and the others 1 point.',
                about_description_2: 'After 10 rounds, the winner is the player with the most points.',
                about_answer: 'Answer :',
                credits_madeBy: 'Made by',
                settings_setsGame: 'sets the game.',
                settings_rounds: 'Rounds:',
                settings_roundDuration: 'Round Duration:',
                settings_seconds: 'seconds',
                settings_startGame: 'Start Game!',
                settings_shareLink: 'Share this link with your friends!',
                input_answer: 'Enter your answer',
                input_message: 'Enter a message',
                chat_disconnect: 'left the game.',
                chat_join: 'joined the game.',
                chat_userFound: 'found the answer.',
                chat_userClose: 'is close!',
                chat_answer: 'Answer:',
                chat_roundStarted_1: 'Round',
                chat_roundStarted_2: 'started.',
                foundWord: [
                    'Great!',
                    'You found it!',
                    'Awesome!',
                    'That\'s it!',
                    'You rock!',
                    'Let\'s win this!',
                ],
                meta_title_main: 'Ezil.io · Guess the word with your friends',
                meta_title_join: 'Ezil.io · Join game',
                meta_title_round: 'Ezil.io · Round',
                meta_description: 'Ezil.io is a free and multiplayer general knowledge game. Fill the blank in the definition to find the answer. Compete with your friend and be the fastest to guess the word!',
                admin_login: 'Log in',
                admin_username: 'Username',
                admin_password: 'Password',
                admin_addWord: 'Add a word',
                admin_add: 'Add',
                admin_verifyWord: 'Approve',
                admin_editWord: 'Edit a word',
                admin_wordsWaiting: 'words pending',
                admin_searchPlaceholder: 'Salamandre (animal légendaire)',
                admin_search: 'Search',
                admin_selectLanguage: 'Language',
                admin_tooLong: 'Too long !',
                admin_already: 'This word is already in game',
                admin_didyoumean: 'Did you mean:',
            },
            fr: {
                language_code: 'fr',
                join_createPrivate: 'Créer une partie privée',
                join_joinRoom: 'Jouer !',
                join_joinPrivateRoom: 'Rejoindre la partie !',
                join_joinRoomX: 'Rejoindre la partie ',
                join_enterName: 'Entrez votre nom',
                join_players: 'joueurs',
                error_usernameTooLong: 'Votre nom doit faire moins de 20 caractères.',
                error_usernameRequired: 'Vous devez choisir un nom.',
                about_howTo: 'Comment jouer ?',
                about_description_1: 'est un jeu de culture générale. Les joueurs doivent retrouver un mot à partir de sa définition en un temps imparti. Le premier joueur à trouver le mot gagne 5 points, le deuxième 3 points, et les suivants 1 point.',
                about_description_2: 'À la fin des 10 manches, le vainqueur est celui ayant gagné le plus de points.',
                about_answer: 'Réponse :',
                credits_madeBy: 'Créé par',
                settings_setsGame: 'prépare la partie.',
                settings_rounds: 'Manches :',
                settings_roundDuration: 'Durée des manches :',
                settings_seconds: 'secondes',
                settings_startGame: 'Lancer la partie !',
                settings_shareLink: 'Partage ce lien à tes amis!',
                input_answer: 'Entrez votre réponse',
                input_message: 'Entrez un message',
                chat_disconnect: 'a quitté la partie.',
                chat_join: 'a rejoint la partie.',
                chat_userFound: 'a trouvé la réponse.',
                chat_userClose: 'est proche!',
                chat_answer: 'Réponse :',
                chat_roundStarted_1: 'La manche',
                chat_roundStarted_2: 'a commencé.',
                foundWord: [
                    'Bravo !',
                    'Trouvé !',
                    'Félicitations !',
                    'Bien joué !',
                    'Excellent !',
                ],
                meta_title_main: 'Ezil.io · Devinez le mot, jouez avec vos amis !',
                meta_title_join: 'Ezil.io · Rejoindre une partie',
                meta_title_round: 'Ezil.io · Manche',
                meta_description: 'Ezil.io est un jeu de culture générale gratuit et multijoueurs. Devinez le mot caché dans la définition pour gagner. Soyez plus rapide que vos amis pour remporter la partie !',
                admin_login: 'Se connecter',
                admin_username: 'Identifiant',
                admin_password: 'Mot de passe',
                admin_addWord: 'Ajouter un mot',
                admin_add: 'Ajouter',
                admin_verifyWord: 'Valider',
                admin_editWord: 'Modifier un mot',
                admin_wordsWaiting: 'mots en attente',
                admin_searchPlaceholder: 'Salamandre (animal légendaire)',
                admin_search: 'Chercher',
                admin_selectLanguage: 'Langue',
                admin_tooLong: 'Trop long !',
                admin_already: 'Ce mot est déjà présent dans le jeu',
                admin_didyoumean: 'Vouliez-vous dire :',
            },
            eo: {
                language_code: 'eo',
                join_createPrivate: 'Krei privatan ludejon!',
                join_joinRoom: 'Ludi!',
                join_joinPrivateRoom: 'Ludi!',
                join_joinRoomX: 'Partopreni ludon ',
                join_enterName: 'Enmetu vian nomon',
                join_players: 'ludantoj',
                error_usernameTooLong: 'Vian nomon estu malpli longa ol 20 literojn.',
                error_usernameRequired: 'Vi devas elekti nomon.',
                about_howTo: 'Kiel ludi?',
                about_description_1: 'estas ludo pri ĝenerala scio. La ludantoj devas trovi vorton el difinon antaŭ la tempolimo. La unua ludanto kiu trovas gajnas 5 puentojn, la dua gajnas 3 puentojn kaj la sekvantaj gajnas 1 puento.',
                about_description_2: 'Post 10 vicoj, venkas la ludantoj kiu gajnis plej multe da puentojn.',
                about_answer: 'Respondo :',
                credits_madeBy: 'Farita de',
                settings_setsGame: 'pretigas la ludon.',
                settings_rounds: 'Vicoj:',
                settings_roundDuration: 'Vica tempolimo:',
                settings_seconds: 'sekundoj',
                settings_startGame: 'Ekludi!',
                settings_shareLink: 'Donu tiun ligilon al viajn amikojn!',
                input_answer: 'Skribu vian respondon',
                input_message: 'Skribu mesaĝon',
                chat_disconnect: 'eliris de la ludo.',
                chat_join: 'eniris la ludon.',
                chat_userFound: 'trovis la vorton.',
                chat_userClose: 'preskaŭ estas la ĝusta respondo!',
                chat_answer: 'Vorto:',
                chat_roundStarted_1: 'Vico',
                chat_roundStarted_2: 'komenciĝis.',
                foundWord: [
                    'Bonege!',
                    'Trovita!',
                    'Jej!',
                    'Mirinde!',
                ],
                meta_title_main: 'Ezil.io · Divenu la vorton, ludu kun viaj amikoj',
                meta_title_join: 'Ezil.io · Partopreni ludon',
                meta_title_round: 'Ezil.io · Vico',
                meta_description: 'Ezil.io : Divenu la kaŝitan vorton por venki. Ludu kun viaj amikoj, kaj estu pli rapida ol ilin!',
                admin_login: 'Konektiĝi',
                admin_username: 'Nomo',
                admin_password: 'Pasvorto',
                admin_addWord: 'Aldoni vorton',
                admin_add: 'Aldoni',
                admin_verifyWord: 'Kontroli',
                admin_editWord: 'Modifii vorton',
                admin_wordsWaiting: 'kontrolotaj vortoj',
                admin_searchPlaceholder: 'Salamandre (animal légendaire)',
                admin_search: 'Serĉi',
                admin_selectLanguage: 'Lingvo',
                admin_tooLong: 'Tro longa!',
                admin_already: 'Tiu vorto jam estas en la ludo',
                admin_didyoumean: 'Ĉu vi celis diri:',
            }
        }
    ]

};