import React, { Component } from "react";
import '../css/AdminPanel.css';
import AdminLogin from './AdminLogin';
import AdminStatsPanel from './AdminStatsPanel';
import AdminAddExtract from './AdminAddExtract';
import Title from './Title';

class AdminPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 'logout'
        }
        this.logIn = this.logIn.bind(this);
        this.backToMain = this.backToMain.bind(this);
    }
    componentDidMount() {
        let serverUrl = 'http://localhost:5000/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:5000/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl });



    }

    componentDidUpdate() {
        if (this.state.page === 'main') this.getStats();
    }

    getStats() {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }

        fetch(this.state.serverUrl + 'a/stats', options)
            .then(res => res.json().then(json => {

                this.setState({ stats: json.stats })

            }));
    }

    logIn(values) {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        }

        fetch(this.state.serverUrl + 'a/login', options)
            .then(res => res.json().then(json => {
                if (json.success) {
                    this.setState({ userUuid: json.uuid, rank: json.rank, languages: json.languages, page: 'main' }, this.getStats)
                }
            }));

    }

    backToMain() {
        this.setState({ page: 'main' })
    }

    render() {

        let pending = 0;
        if (this.state.stats) {
            for (let item in this.state.stats) {
                pending += this.state.stats[item].pending;
            }
        }
        return (
            <div id="AdminPanel">
                {this.state.page !== 'logout' ?
                    <>
                        {this.state.page === 'main' ?
                            <>
                                {this.state.stats ?
                                    <AdminStatsPanel stats={this.state.stats} />
                                    : null}

                                <div class="adminButtons">
                                    <button class="box adminButton" onClick={() => this.setState({ page: 'add' })}  >{this.props.strings['admin_addWord']}</button>
                                    {this.state.rank > 1 ?
                                        <button class="box adminButton">{this.props.strings['admin_verifyWord']}<br />
                                            <span>{pending} {this.props.strings['admin_wordsWaiting']}</span>
                                        </button>
                                        : null}
                                    {this.state.rank > 1 ? <button class="box adminButton">{this.props.strings['admin_editWord']}</button> : null}
                                </div>
                            </>
                            : <>
                                {this.state.page === 'add' ?

                                    <AdminAddExtract
                                        strings={this.props.strings}
                                        getWikiData={this.getWikiData}
                                        languages={this.state.languages}
                                        disabled={this.state.blockSubmit}
                                        user={this.state.userUuid}
                                        backToMain={this.backToMain}
                                    />

                                    : null}


                            </>
                        }
                    </>
                    :
                    <>
                        <Title />
                        <AdminLogin strings={this.props.strings} logIn={this.logIn} />
                    </>
                }
            </div>
        );
    }
}


export default AdminPanel;
