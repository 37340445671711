import React, { Component } from "react";
import '../css/Extract.css';


class Extract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roundStarted: false
        }

    }
    componentDidMount() {
        // this.startAnimation();

    }
    componentDidUpdate(oldProps) {

        if ((this.props.status !== oldProps.status) && (this.props.status === 'round')) {
            this.startAnimation();
        } else if (this.state.roundStarted === false && this.props.status === 'round') {
            this.startAnimation();
        } else if ((this.props.status !== oldProps.status) && (this.props.status !== 'round')) {

            this.setState({ roundStarted: false })
        }
    }
    startAnimation() {
        this.setState({ roundStarted: true })
        if (this.props.extract) {
            let extract = this.props.extract.replace(/(\r|\n)/g, ' <br> '); //.replace(/-/g, ' - ');

            extract = extract.split(' ');//every space not preceded by <span
            // extract = extract.split(/(?<!<span) /gi); //every space not preceded by <span

            this.setState({ extract: extract })

            let startWord = 0;
            let timeRemaining = this.props.timeRemaining;
            if (timeRemaining < 0 || timeRemaining === -0) timeRemaining = this.props.timePerRound;

            startWord = Math.round(((this.props.timePerRound - timeRemaining) * 1000 + 50) / 300);
            // startWord = Math.round(extract.length - (timeRemaining / this.props.timePerRound) * extract.length);
            //12 20 76
            //0

            this.resetWords();

            if (startWord > 0) {
                setTimeout(this.catchUpWords.bind(null, startWord), 30);
            }
            setTimeout(this.displayWord.bind(null, startWord, extract.length, this.displayWord), 30);
        }
    }
    resetWords() {
        let stop = false;
        for (let i = 0; stop; i++) {
            if (document.getElementById('word_' + i)) {
                document.getElementById('word_' + i).classList.remove('wordDisplay');
                document.getElementById('word_' + i).classList.add('wordHidden');
            } else {
                stop = true;
            }
        }
    }
    catchUpWords(startWord) {
        for (let i = 0; i < startWord; i++) {

            if (!document.getElementById('word_' + i)) return;
            document.getElementById('word_' + i).classList.remove('wordHidden');
            document.getElementById('word_' + i).classList.add('wordDisplay');
        }
    }
    displayWord(word, max, displayWord) {

        if (!document.getElementById('word_' + word)) return;

        document.getElementById('word_' + word).classList.remove('wordHidden');
        document.getElementById('word_' + word).classList.add('wordDisplay');

        if (word < max - 1) {
            word++;
            setTimeout(displayWord.bind(null, word, max, displayWord), 300);
        }

    }
    render() {

        let extractContainer = [];
        if (this.props.status === 'round') {

            if (this.state.extract) {
                for (let i = 0; i < this.state.extract.length; i++) {
                    let word = this.state.extract[i];
                    if (word.match(/<blank>/)) {
                        // let part1 = word.replace(/(.+|)<blank>(.+)<\/blank>(.+|)/g, '$1').replace(/<br>/g, '\n');
                        // let part2 = word.replace(/(.+|)<blank>(.+)<\/blank>(.+|)/g, '$2').replace(/<br>/g, '\n');
                        // let part3 = word.replace(/(.+|)<blank>(.+)<\/blank>(.+|)/g, '$3').replace(/<br>/g, '\n');

                        let splitWord = word.split(/<blank>([a-zA-Z_']+)<\/blank>/g);

                        word = <span>{splitWord.map(item => (
                            item.substr(0, 1) === '_' ? <span class="obfuscatedWord">{item}</span> : item
                        ))
                        }</span>
                        // word = <span>{part1}<span class="obfuscatedWord">{part2}</span>{part3}</span>
                    } else {
                        word = word.replace(/<br>/g, '\n');
                    }

                    extractContainer.push(<span id={'word_' + i} className='wordHidden'>{word} </span>)
                }
            }

        }

        return (
            <div id="Extract">
                {this.props.status === 'round' ? extractContainer : this.props.extract}
            </div>
        );


    }
}


export default Extract;
