import React, { Component } from "react";
import '../css/AdminPanel.css';

class AdminStatsPanelItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    render() {

        let language = this.props.item.language;

        if (language === 'en') language = 'English';
        else if (language === 'fr') language = 'Français';
        else if (language === 'eo') language = 'Esperanto';

        return (
            <div class="box languageStat" id="AdminStatsPanelItem">
                {this.props.item.count}
                <div class="bottomlanguageStat">{language}</div>
            </div>
        );
    }
}


export default AdminStatsPanelItem;
