import React, { Component } from "react";
import '../css/Chat.css';


class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    componentDidUpdate(oldProps) {
        if (window.innerWidth <= 800) {
            var objDiv = document.getElementById("chatMessages");
            objDiv.scrollTop = objDiv.scrollHeight;
        }


        if (this.props.chat !== oldProps.chat) {
            let chatMessages = this.props.chat;


            if (window.innerWidth > 800) {
                chatMessages.sort((a, b) => { return b.timestamp - a.timestamp })
            }
            this.setState({ chatMessages: chatMessages })
        }
    }
    render() {
        let chatMessages = []
        if (this.state.chatMessages) chatMessages = this.state.chatMessages;
        return (
            <div id="Chat">
                <div class="chatMessages" id="chatMessages">
                    {chatMessages.map(item => (
                        <li key={item.timestamp}>{item.message}</li>
                    ))}
                </div>
                <div id="gradient"></div>
            </div>
        );
    }
}


export default Chat;
