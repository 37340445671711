import React, { Component } from "react";
import '../css/Title.css';

class Title extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    render() {
        return (
            <div id="Title">
                <h1 className="titleAnimation">
                    <span>E</span>
                    <span>z</span>
                    <span>i</span>
                    <span>l</span>
                    <span>.</span>
                    <span>i</span>
                    <span>o</span>
                </h1>
            </div>
        );
    }
}


export default Title;
