import React, { Component } from "react";
import '../css/AddEntry.css';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';


class AddEntry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: 'waiting',
            blockSubmit: true,
            exist: false,
            searchQuery: ['a', 'b', 'c']
        }
    }
    componentDidMount() {
        let serverUrl = 'http://localhost:5000/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:5000/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl })
        this.getWikiData({ page: 'pain au chocolat' });

    }

    checkIfAlreadyPageExists() {
        const params = new URLSearchParams({ pageId: this.state.pageId })

        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'extract/get?' + params, options)
            .then(res => res.json().then(json => {
                console.log(json)
                if (json.found) {
                    this.setState({ blockSubmit: true, exist: true })
                } else {
                    this.setState({ blockSubmit: false, exist: false })
                }
            }))
            .catch(err => console.log(err));
    }
    getWikiData(values) {
        console.log(values)
        let query = values.page;
        this.setState({
            status: 'fetching',
            query: query,
            username: values.username,
            password: values.password,
            blockSubmit: true,
            exist: false
        });

        query = query.replace(' ', '_');

        const sentences = 6;
        let url = 'https://fr.wikipedia.org/w/api.php?action=query&format=json&origin=*&prop=extracts&titles=' + query + '&exsentences=' + sentences + '&exintro=1';

        fetch(url)
            .then(res => res.json().then(json => {


                let pageId = Object.keys(json.query.pages)[0];
                let extract = json.query.pages[pageId].extract;

                if (extract) {
                    // If we hit a page, display the extract
                    this.setState({ searchQuery: [], blockSubmit: false })
                    this.parseWikiData(json);
                } else {
                    // If no page is found, search wikipedia
                    this.setState({ status: 'error' });
                    // let url = 'https://fr.wikipedia.org/w/api.php?action=query&list=search&origin=*&srsearch=' + this.state.query + '&format=json';

                    // fetch(url)
                    //     .then(res => res.json().then(json => {
                    //         let results = json.query.search;
                    //         this.setState({ searchQuery: results })
                    //         console.log(json.query.search)
                    //     }));

                }

            }));
    }
    parseWikiData(data) {

        let pageId = Object.keys(data.query.pages)[0];
        let extract = data.query.pages[pageId].extract;
        let pageTitle = data.query.pages[pageId].title;

        if (extract) {

            // Remove parenthesis from the title
            let answer = pageTitle.replace(/\((.+)\)/g, "");
            answer = answer.trim();
            extract = extract.trim();


            // Remove HTML tags
            extract = extract.replace(/<\/?[^>]+(>|$)/g, "").replace(/&#160;/g, ' ').trim()


            this.setState({
                pageId: pageId,
                extract: extract,
                answer: answer,
                pageTitle: pageTitle,
                status: 'reviewing'
            });
            this.generateDisplayExtract();
            this.checkIfAlreadyPageExists()
        } else {
            this.setState({ status: 'error', blockSubmit: 'true' })
        }
    }

    generateDisplayExtract() {

        const extract = this.state.extract;
        // console.log(extract)
        const answer = this.state.answer;

        let extractDisplay = this.obfuscateExtract(extract, answer)
        /*
                let words = answer.split(' ');
        
                // Blank is the same length as the title
                let blank = '';
                // let blank = '<span class="obfuscatedWord">';
        
                for (let i = 0; i < answer.length; i++) {
                    if (answer[i] === ' ') blank += ' ';
                    else blank += '_';
                }
                // blank += '</span>';
        
        
                // Replace the page title by the blank
                const extractRegex = new RegExp(this.state.answer, 'gi');
                let extractDisplay = extract.replace(extractRegex, blank);
        
                console.log(words.length)
                if (words.length > 1) {
                    for (let i = 0; i < words.length; i++) {
                        console.log(words[i])
                        if (
                            words[i].toLowerCase() !== 'de'
                            && words[i].toLowerCase() !== 'du'
                            && words[i].toLowerCase() !== 'des'
                            && words[i].toLowerCase() !== 'au'
                            && words[i].toLowerCase() !== 'aux'
                            && words[i].toLowerCase() !== 'en'
                            && words[i].toLowerCase() !== 'à'
                            && words[i].toLowerCase() !== 'la'
                            && words[i].toLowerCase() !== 'le'
                            && words[i].toLowerCase() !== 'in'
                            && words[i].toLowerCase() !== 'ex'
                        ) {
                            let blank2 = '';
                            // let blank2 = '<span class="obfuscatedWord">';
                            for (let j = 0; j < words[i].length; j++) {
                                blank2 += '_';
                            }
                            // blank2 += '</span>'
        
                            const extractRegex = new RegExp(words[i].toLowerCase(), 'gi');
                            extractDisplay = extractDisplay.replace(extractRegex, blank2);
                        }
                    }
                }*/

        // let extractRegex = new RegExp(wordListRegex, 'gi');

        this.setState({
            extractDisplay: extractDisplay
        });
    }
    registerExtract() {

        this.setState({ status: 'submitting' })
        const data = {
            pageId: this.state.pageId,
            extract: this.state.extract,
            answer: this.state.answer,
            pageTitle: this.state.pageTitle,
            username: this.state.username,
            password: this.state.password,
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }

        console.log(data)
        fetch(this.state.serverUrl + 'extract/new', options)
            .then(res => res.json().then(json => {
                console.log(json);
                if (json.message === 'success') {
                    this.setState({ status: 'success' })
                }
                else if (json.message === 'authentication') {
                    this.setState({ status: 'authentication error' })
                }
                this.checkIfAlreadyPageExists()
            }));
    }

    changeExtract() {
        this.setState({
            extract: document.getElementById('extract').innerHTML,

        },
            this.generateDisplayExtract);

    }

    obfuscateExtract(extract, answer) {

        let words = answer.split(' ');
        // Blank is the same length as the title
        // let blank = '';
        let blank = '<blank>';
        let prev = false;
        for (let i = 0; i < answer.length; i++) {
            if (answer[i] === ' ') { blank += '</blank> '; prev = true } // Pour les mots composés
            else {
                if (prev) blank += '<blank>'
                if (answer[i] === '-') blank += '</blank>-<blank>';
                else blank += '_';
                prev = false;
            }
        }

        blank += '</blank>';

        // Replace the page title by the blank
        const extractRegex = new RegExp(answer, 'gi');
        let extractDisplay = extract.replace(extractRegex, blank);

        if (words.length > 1) {
            for (let i = 0; i < words.length; i++) {
                if (
                    words[i].toLowerCase() !== 'de'
                    && words[i].toLowerCase() !== 'du'
                    && words[i].toLowerCase() !== 'des'
                    && words[i].toLowerCase() !== 'au'
                    && words[i].toLowerCase() !== 'aux'
                    && words[i].toLowerCase() !== 'en'
                    && words[i].toLowerCase() !== 'à'
                    && words[i].toLowerCase() !== 'la'
                    && words[i].toLowerCase() !== 'le'
                    && words[i].toLowerCase() !== 'in'
                    && words[i].toLowerCase() !== 'ex'
                    && words[i].toLowerCase() !== 'et'
                ) {
                    // let blank2 = '';
                    let blank2 = '<blank>';
                    for (let j = 0; j < words[i].length; j++) {
                        blank2 += '_';
                    }
                    blank2 += '</blank>';

                    const extractRegex = new RegExp(words[i].toLowerCase(), 'gi');
                    extractDisplay = extractDisplay.replace(extractRegex, blank2);
                }
            }
        }
        return extractDisplay;
    }
    render() {
        const registerExtractBound = this.registerExtract.bind(this);
        const changeExtractBound = this.changeExtract.bind(this);
        let extractContainer = [];
        let warningStyle = { color: 'black' }
        if (this.state.extractDisplay) {
            if (this.state.extractDisplay.length >= 800) warningStyle = { color: 'orange' }
            if (this.state.extractDisplay.length >= 1000) warningStyle = { color: 'red' }


            let extract = this.state.extractDisplay.replace(/(\r|\n)/g, ' <br> '); //.replace(/-/g, ' - ');
            extract = extract.split(' ');//every space not preceded by <span


            for (let i = 0; i < extract.length; i++) {
                let word = extract[i];
                if (word.match(/<blank>/)) {
                    // let part1 = word.replace(/(.+|)<blank>(.+)<\/blank>(.+|)/g, '$1').replace(/<br>/g, '\n');
                    // let part2 = word.replace(/(.+|)<blank>(.+)<\/blank>(.+|)/g, '$2').replace(/<br>/g, '\n');
                    // let part3 = word.replace(/(.+|)<blank>(.+)<\/blank>(.+|)/g, '$3').replace(/<br>/g, '\n');

                    let splitWord = word.split(/<blank>([a-zA-Z_']+)<\/blank>/g);

                    word = <span>{splitWord.map(item => (
                        item.substr(0, 1) === '_' ? <span class="obfuscatedWord">{item}</span> : item
                    ))
                    }</span>
                    // word = <span>{part1}<span class="obfuscatedWord">{part2}</span>{part3}</span>
                } else {
                    word = word.replace(/<br>/g, '\n');
                }

                extractContainer.push(<span id={'word_' + i}>{word} </span>)
            }
            console.log('>', extractContainer)
            console.log('>>', this.state.extractDisplay)
        }
        return (
            <div id="AddEntry">
                <Formik
                    initialValues={{
                        page: '',
                        username: '',
                        password: '',
                    }}
                    validationSchema={
                        Yup.object({
                            username: Yup.string()
                                .required('Required'),
                            page: Yup.string()
                                .required('Required'),
                            password: Yup.string()
                                .required('Required'),
                        })
                    }
                    onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        this.getWikiData(values);
                    }}
                >
                    <Form className="addEntryForm">
                        Identitifiant <Field id="username" name="username" required />
                        Mot de passe <Field id="password" name="password" type="password" required /><br />
                        <Field id="page" name="page" placeholder="Salamandre (animal légendaire)" required className="addEntryQuery" />

                        <button className='addEntrySubmit'  > Chercher</button>

                        <br />
                        <p>{this.state.status}</p>
                        {/* <div>{this.state.searchQuery.map((item) => <li>{item}</li>)}</div> */}
                    </Form>

                </Formik>

                <h2 style={{ color: '#fafafa' }}>{this.state.answer}</h2>
                <p id="Extract" style={{ fontSize: '1.2em' }}>{extractContainer}</p>
                <Formik
                    initialValues={{
                        extract: this.state.extract,
                    }}
                    onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        this.getWikiData(values);
                    }}
                    enableReinitialize='true'
                >
                    <Form className="changeExtract">
                        <Field id="extract" name="extract" as='textarea' className="addEntryExtract" onBlur={changeExtractBound} />
                    </Form>

                </Formik>
                {this.state.extractDisplay ?

                    <span style={warningStyle}>{this.state.extractDisplay.length}</span>
                    : null}<br />
                <button className='addEntrySubmit' onClick={registerExtractBound} disabled={this.state.blockSubmit} >Ajouter</button><br />
                {this.state.exist ? 'Ce mot est présent dans la base de données' : null}
            </div>
        );
    }
}


export default AddEntry;
