import React, { Component } from "react";
import '../css/Countdown.css';

class Countdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    render() {
        let timePercentage = (100 - Math.round((this.props.timeRemaining / this.props.timePerRound) * 100));
        if (timePercentage <= 0 || typeof timePercentage == 'string') timePercentage = 0;
        timePercentage = timePercentage + '%';

        let pageSource = 'http://fr.wikipedia.com/wiki/' + this.props.pageTitle;
        return (
            <div id="Countdown">

                <div id="timeRemaining" className={!this.props.status === 'round' ? 'timeOff' : null}>{this.props.status === 'round' ? this.props.timeRemaining : this.props.answer}{this.props.status === 'roundEnd' ? <a className="source" href={pageSource}>Wikipedia</a> : null}</div>
                <div class="timeBarContainer">

                    <div style={this.props.status === 'round' ? { width: timePercentage } : null} className={this.props.status === 'roundEnd' ? 'endRoundTimeBar timeBar' : 'timeBar'}></div>
                </div>
            </div>
        );
    }
}


export default Countdown;
