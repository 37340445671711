import React, { Component } from "react";
import { Formik, Field, Form } from 'formik';
import '../css/MainInput.css';
import * as Yup from 'yup';


class MainInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    submitForm(values) {
        let isPlaying = this.isUserPlaying();
        if (isPlaying) {
            this.props.submitAnswer(values);
        } else {
            this.props.submitChat(values);
        }
        // console.log(document.getElementById('answer').value);
        document.getElementById('answer').value = '';
    }
    isUserPlaying() {
        let isPlaying = this.props.status === 'round' ? true : false;

        if (this.props.playersWhoFound.includes(this.props.uuid)) {
            isPlaying = false;
        }
        return isPlaying;
    }
    render() {
        let isPlaying = this.isUserPlaying();

        let inputPlaceholder = isPlaying ? this.props.strings['input_answer'] : this.props.strings['input_message'];
        return (
            <div id="MainInput">
                <Formik
                    initialValues={{
                        answer: ''
                    }}

                    validationSchema={
                        Yup.object({
                            answer: Yup.string()
                                .min(1)
                                .required('Required'),
                        })
                    }
                    onSubmit={async (values, actions) => {
                        this.submitForm(values);
                        actions.resetForm({
                            values: { answer: '' },
                        });
                    }}>
                    <Form className="mainInputForm">
                        <Field id="answer" name="answer" placeholder={inputPlaceholder} className="mainInputInput" autoFocus min="1" />
                        <button type="submit" className={isPlaying ? 'submitAnswer' : 'submitMessage'}>{isPlaying ? '→' : '→'}</button>
                    </Form>
                </Formik>
            </div>
        );
    }
}


export default MainInput;
