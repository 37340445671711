import React, { Component } from "react";
import '../css/AboutGame.css';

class AboutGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    render() {
        return (
            <div id="AboutGame">
                <div className="box boxJoinGame">
                    <h2>{this.props.strings['about_howTo']}</h2>
                    <span className="hiddenWord">___________</span><span class="aboutText"> {this.props.strings['about_description_1']}</span><br />
                    <span class="aboutText">{this.props.strings['about_description_2']}</span><br /><br />

                    <em class="aboutText">{this.props.strings['about_answer']}</em> <b>Ezil.io</b>
                </div>
            </div>
        );
    }
}


export default AboutGame;
