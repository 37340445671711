import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../css/JoinGame.css';
import AboutGame from './AboutGame';
import Title from './Title';
import LocaleSelector from './LocaleSelector';
// import {
//     initiateSocket,
//     subscribeToGame, subscribeToChat, sendMessage,
//     requestGameStart, sendAnswer, requestGameReset
// } from './Socket';


class JoinGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ''
        }
    }





    componentDidMount() {
        this.setState({ username: window.localStorage.getItem('username') })









        // initiateSocket('ping', 'truc', 'machin');










    }
    handleSubmit(values) {
        if (values.create === 1) this.props.createRoom(values);
        else if (values.create === 0) this.props.joinRoom(values);
    }
    render() {
        return (
            <div id="JoinGame">
                <LocaleSelector changeLocale={this.props.changeLocale} strings={this.props.strings} />
                <Title />
                <div className="box boxJoinGame">
                    <Formik
                        initialValues={{
                            username: window.localStorage.getItem('username'),
                            language: this.props.strings['language_code'] || window.localStorage.getItem('locale'),
                        }}

                        validationSchema={
                            Yup.object({

                                username: Yup.string().nullable()
                                    .max(20, this.props.strings['error_usernameTooLong'])
                                    .min(2)
                                    .required(this.props.strings['error_usernameRequired']),
                            })
                        }

                        onSubmit={async (values) => {
                            this.handleSubmit(values);
                        }}
                    >{({ setFieldValue, handleSubmit }) => (
                        <Form className="formJoinGame">
                            <div className="nameLanguageInputs">
                                <Field id="username" name="username" placeholder={this.props.strings['join_enterName']} className="inputUsername" />
                                <Field as="select" name="language" className="inputLanguage">
                                    <option value="en" selected={this.props.strings['language_code'] === 'en' ? 'selected' : null}>English</option>
                                    {/* <option value="eo" selected={this.props.strings['language_code'] === 'eo' ? 'selected' : null}>Esperanto</option> */}
                                    <option value="fr" selected={this.props.strings['language_code'] === 'fr' ? 'selected' : null}>Français</option>
                                </Field>
                            </div>
                            <span className="formError"><ErrorMessage name="username" /></span>
                            <button type="button" className="buttonJoinGame" onClick={() => {
                                setFieldValue('create', 0, false)
                                handleSubmit()
                            }}>{this.props.strings['join_joinRoom']}</button><br />
                            <button type="button" className="buttonCreateGame" onClick={() => {
                                setFieldValue('create', 1, false)
                                handleSubmit()
                            }}>{this.props.strings['join_createPrivate']}</button>
                        </Form>
                    )}

                    </Formik>
                </div>
                <AboutGame strings={this.props.strings} />

                {/* <WordFoundAnimation strings={this.props.strings} /> */}

                <a target="_blank" href="http://www.twitter.com/BrunoLefevre" rel="noreferrer" className="credits">{this.props.strings['credits_madeBy']} @<span className="name">BrunoLefevre</span></a>
            </div >
        );
    }
}


export default JoinGame;