import React, { Component } from "react";
import '../css/ScoreBoard.css';
import confetti from 'canvas-confetti';

class ScoreBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    launchConfetti() {
        var count = 200;
        var defaults = {
            origin: { y: 0.7 },
            zIndex: 10,
            colors: ['#CDE7B0', '#FE938C', '#B4ADEA']
        };

        function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
        }

        fire(0.25, {
            spread: 35,
            startVelocity: 55,
        });
        fire(0.2, {
            spread: 90,
        });
        fire(0.35, {
            spread: 120,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 150,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 160,
            startVelocity: 45,
        });
    }

    render() {
        let playerList = [];
        for (let element in this.props.players) {
            playerList.push(this.props.players[element]);
        }

        playerList.sort((a, b) => { return b.score - a.score });

        if (playerList[0] && playerList[0].uuid === this.props.uuid) {
            this.launchConfetti();
        }

        for (let i = 0; i < playerList.length; i++) {
            // console.log(this.props.players[playerList[i]].username, this.props.players[playerList[i]].score)
        }
        let listDisplay = playerList.map(item => (
            <li key={item.uuid} class="scoreBoard_Item"><span class={item.you ? 'scoreBoard_Username list_You' : 'scoreBoard_Username'}>{item.username}</span><span class="scoreBoard_Score">{item.score ? item.score : 0}</span></li>));

        return (
            <div id="ScoreBoard">
                <ol class="scoreBoard_List">
                    {listDisplay}
                </ol>
                {this.props.admin === this.props.uuid ?
                    <button class='buttonJoinGame restartButton' onClick={this.props.restartGame}>Restart Game</button>
                    : null}
            </div>
        );
    }
}


export default ScoreBoard;
