import React, { Component } from "react";
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Helmet } from 'react-helmet';
import Sound from 'react-sound';
import * as Yup from 'yup';
import sfx_roundStart from '../sounds/roundStart.mp3';
import sfx_roundEnd from '../sounds/roundEnd.mp3';
import sfx_gameEnd from '../sounds/gameEnd.mp3';
import sfx_timerTick from '../sounds/timerTick.mp3';
import sfx_answerFound from '../sounds/answerFound.mp3';
import '../css/Room.css';
import {
    initiateSocket,
    subscribeToGame, subscribeToChat, subscribeToSettings, sendMessage, updateSettings,
    requestGameStart, sendAnswer, requestGameReset
} from './Socket';
import PlayerList from "./PlayerList";
import Chat from "./Chat";
import MainInput from "./MainInput";
import Extract from "./Extract";
import RoomSettings from "./RoomSettings";
import Countdown from './Countdown';
import ScoreBoard from './ScoreBoard';
import AboutGame from './AboutGame';
import WordFoundAnimation from './WordFoundAnimation';
import Title from './Title';



class Room extends Component {
    constructor(props) {
        super(props);
        this.startGame = this.startGame.bind(this);
        this.submitChat = this.submitChat.bind(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.restartGame = this.restartGame.bind(this);
        this.propagateFieldChange = this.propagateFieldChange.bind(this);

        this.state = {
            username: this.props.username,
            roomId: props.match.params.id,
            chat: [],
            status: 'offline',
            players: [],
            foundAnimation: 0,
            playersWhoFound: [],
            uuid: '',
            soundOn: 1
        }
    }
    componentDidUpdate(oldProps) {
        if (oldProps.roomId !== this.props.roomId) this.setState({ roomId: this.props.roomId });
        if (oldProps.serverUrl !== this.props.serverUrl) this.checkIfRoomExists();
    }
    componentDidMount() {
        if (window.localStorage.getItem('uuid')) this.setState({ uuid: window.localStorage.getItem('uuid') })
        if (window.localStorage.getItem('soundOn')) this.setState({ soundOn: parseInt(window.localStorage.getItem('soundOn')) })
        if (this.props.serverUrl) this.checkIfRoomExists();

        let serverUrl = 'http://localhost:5000/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:5000/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl });
    }
    checkIfRoomExists() {
        const params = new URLSearchParams({ roomId: this.state.roomId, uuid: this.state.uuid })
        // console.log('checsking')
        // console.log(this.props.serverUrl + 'room/check?' + params)
        const options = {
            method: 'GET'
        }
        console.log(this.props.serverUrl)
        fetch(this.props.serverUrl + 'room/check?' + params, options)
            .then(res => res.json().then(json => {
                if (json.message === 'success') {
                    if (this.props.username) {
                        this.setState({ username: this.props.username })
                        this.setupGame();
                    } else {

                        this.setState({ playerCount: json.playerCount })

                    }
                }
                else if (json.message === 'rejoin') {
                    this.setState({ username: json.username })
                    this.setupGame();
                }
                else {
                    this.setState({ status: 'noroom' })
                }
            }))
            .catch(err => console.log(err));
    }
    startGame(values) {
        let options = { roundDuration: values.roundDuration, roundQuantity: values.roundQuantity }
        requestGameStart(this.state.roomId, options, this.state.uuid);

    }
    handleJoinGame(values) {
        console.log(values)
        if (values && values.username !== undefined) {
            window.localStorage.setItem('username', this.state.username);
            this.setState({ username: values.username });
            this.setupGame();
        }
    }
    setupGame() {
        console.log('setup', this.state.username)
        window.localStorage.setItem('username', this.state.username);
        if (this.state.roomId) initiateSocket(this.state.roomId, this.state.username, this.state.uuid);
        subscribeToGame((err, data) => {
            if (err) return;
            // console.log(data);
            if (data.message === 'userJoined') this.userJoined(data);
            else if (data.message === 'userDisconnected') this.userDisconnected(data);
            else if (data.message === 'roomNotFound') this.props.roomNotFound();
            else if (data.message === 'roomFull') this.props.roomNotFound();
            else if (data.message === 'socketId') this.updateSocketId(data);
            else if (data.message === 'newRound') this.newRound(data);
            else if (data.message === 'playerFound') this.playerFound(data);
            else if (data.message === 'playerAnswer') this.playerAnswer(data);
            else if (data.message === 'playerClose') this.playerClose(data);
            else if (data.message === 'timer') this.updateTimer(data);
            else if (data.message === 'roundEnd') this.roundEnd(data);
            else if (data.message === 'gameEnd') this.gameEnd(data);
            else if (data.message === 'resetGame') this.resetGame(data);

            if (data.game) this.updateGameState(data.game);
        });
        subscribeToChat((err, data) => {
            if (err) return;
            let chatMessage = <span class="chat_Message"><span class="chat_User">{data.username}</span> {data.message}</span>;
            this.addToChat(chatMessage);
        });
        subscribeToSettings((err, data) => {
            if (err) return;
            if (data.uuid !== this.state.uuid) {
                if (data.field === 'roundDuration') this.setState({ updatedRoundDuration: data.value })
                else if (data.field === 'roundQuantity') this.setState({ updatedRoundQuantity: data.value })
            }
        });
        this.keepAlive();
    }
    updateSocketId(data) {
        this.setState({
            socketId: data.socketId,
            username: data.username,
            uuid: data.uuid,
        });
        window.localStorage.setItem('uuid', data.uuid);
    }
    updateGameState(game) {
        this.setState({
            status: game.status ? game.status : this.state.status,
            admin: game.admin ? game.admin : this.state.admin,
            players: game.players ? game.players : this.state.players,
            extract: game.extract ? game.extract : null,
            pageTitle: game.pageTitle ? game.pageTitle : game.pageTitle,
            results: game.results ? game.results : this.state.results,
            timeRemaining: game.timeRemaining ? game.timeRemaining : this.state.timeRemaining
        })
    }
    updateTimer(data) {
        this.setState({ timeRemaining: Math.round(data.timeRemaining / 1000), timePerRound: data.timePerRound })
        if (Math.round(data.timeRemaining / 1000) <= 5 && Math.round(data.timeRemaining / 1000) > 0) {
            this.setState({ sound: sfx_timerTick });
        }
    }
    playerAnswer(data) {
        let chatMessage = <span class="chat_Message"><span class="chat_User">{data.username}</span> {data.answer}</span>;
        this.addToChat(chatMessage);
    }
    playerClose(data) {
        let chatMessage = <span class="chat_UserClose">"{data.answer}" {this.props.strings['chat_userClose']}</span>;
        this.addToChat(chatMessage);
    }
    playerFound(data) {
        this.setState({ playersWhoFound: data.playersWhoFound })
        let chatMessage = <span class="chat_UserFound">{data.username} {this.props.strings['chat_userFound']}</span>;
        this.addToChat(chatMessage);

        // console.log(document.getElementById(data.uuid))
        // Tooltip animation

        if (data.username === this.state.username) {
            const foundMessage = this.props.strings['foundWord'][Math.floor(Math.random() * this.props.strings['foundWord'].length)];
            this.setState({ foundAnimation: 1, foundMessage: foundMessage });
        }


        this.setState({ sound: sfx_answerFound });
    }
    newRound(data) {
        this.setState({ playersWhoFound: [], foundAnimation: 0 })
        let chatMessage = <span class="chat_RoundStarted">{this.props.strings['chat_roundStarted_1']} {data.game.round}/{data.game.roundQuantity} {this.props.strings['chat_roundStarted_2']}</span>;
        this.addToChat(chatMessage);

        this.setState({ sound: sfx_roundStart, round: data.game.round, roundQuantity: data.game.roundQuantity });
    }
    roundEnd(data) {
        let chatMessage = <span class="chat_Answer">{this.props.strings['chat_answer']} {data.game.answer}</span>;
        this.setState({ answer: data.game.answer, sound: sfx_roundEnd });
        this.addToChat(chatMessage);

    }
    gameEnd(data) {
        this.setState({ status: 'gameEnd', sound: sfx_gameEnd })

    }
    userJoined(data) {
        if (!data.playerAlreadyInGame) {
            let chatMessage = <span class="chat_UserJoined">{data.username} {this.props.strings['chat_join']}</span>;
            this.addToChat(chatMessage);
        }
    }
    userDisconnected(data) {
        let chatMessage = <span class="chat_UserLeft">{data.username} {this.props.strings['chat_disconnect']}</span>;
        this.addToChat(chatMessage);
    }
    addToChat(message) {
        let id = Date.now() + (Math.random() * 1000) / 1000;
        let timestamp = Date.now();
        this.setState(prevState => ({
            chat: [...prevState.chat, { id: id, message: message, timestamp: timestamp }]
        }))
    }
    submitAnswer(values) {
        sendAnswer(this.state.roomId, values.answer, this.state.uuid)
    }
    submitChat(values) {
        sendMessage(this.state.roomId, values.answer, this.state.username);
    }
    clickBottomUI(e) {
        if (e.target.id !== 'bottomUI' && window.innerWidth > 800)
            return;
        document.getElementById("answer").focus();
    }
    restartGame() {
        requestGameReset(this.state.roomId, this.state.uuid);
    }
    resetGame() {
        //
    }
    handleAudioFinishedPlaying() {
        this.setState({ sound: null })
    }
    toggleAudio() {
        this.setState({ soundOn: this.state.soundOn ? 0 : 1 }, function () {
            window.localStorage.setItem('soundOn', this.state.soundOn);
        }
        );
    }
    keepAlive() {
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'k', options)
        // .then(res => res.json().then(json => {
        //     // if (json.message === 'success') {
        //     //     console.log('its alive')
        //     // }
        // }))
        // .catch(err => console.log(err));

        setTimeout(this.keepAlive.bind(this), 1000 * 60 * 2); // Toutes les deux minutes on ping le serveur pour qu'il reste éveillé.
    }
    propagateFieldChange(changedField, changedValue) { // Displays the updated room settings to other clients
        if (this.state.uuid === this.state.admin) updateSettings(this.state.roomId, changedField, changedValue, this.state.uuid); // Sends socket to other clients
    }
    render() {

        let webPageTitle = this.props.strings['meta_title_main'];
        if ((this.state.status === 'round' || this.state.status === 'roundEnd') && this.state.round !== undefined && this.state.roundQuantity !== undefined) {
            webPageTitle = this.props.strings['meta_title_round'] + ' ' + this.state.round + '/' + this.state.roundQuantity;
        }

        if (this.state.status === 'noroom') {
            return (
                <Redirect to='/' />
            )
        }
        if (!this.state.username) {
            let username = window.localStorage.getItem('username');
            return (
                <div id="connectToRoom">
                    <Title />
                    <div className="box">
                        <p className="joinMessage">{this.props.strings['join_joinRoomX']} {this.state.roomId}</p>
                        <p class="playerCount">{this.state.playerCount}<span class="playerMax"> / 10</span> {this.props.strings['join_players']}</p>
                        <Formik
                            initialValues={{
                                username: username
                            }}
                            validationSchema={
                                Yup.object({
                                    username: Yup.string().nullable()
                                        .max(20, this.props.strings['error_usernameTooLong'])
                                        .min(2)
                                        .required(this.props.strings['error_usernameRequired']),
                                })
                            }
                            onSubmit={async (values) => {
                                await new Promise((r) => setTimeout(r, 50));
                                this.handleJoinGame(values);
                            }}>

                            <Form className="formJoinGame">
                                <Field id="username" name="username" placeholder={this.props.strings['join_enterName']} className="inputUsername" /><br />
                                <span className="formError"><ErrorMessage name="username" /></span>
                                <button type="submit" className="buttonJoinGame" disabled={this.state.playerCount === 10 ? 'disabled' : null}>{this.props.strings['join_joinPrivateRoom']}</button>
                            </Form>
                        </Formik>
                    </div>

                    <AboutGame strings={this.props.strings} />

                    <Helmet>
                        <title>{this.props.strings['meta_title_join']}</title>
                    </Helmet>
                </div >
            )
        }


        let extract = this.state.extract;
        return (
            <div id="Room">
                <div class={this.state.soundOn === 1 ? 'audioOn' : 'audioOff'} onClick={this.toggleAudio.bind(this)}></div>
                {(this.state.status !== 'gameEnd') ?
                    <PlayerList
                        players={this.state.players}
                        playersWhoFound={this.state.playersWhoFound}
                        admin={this.state.admin}
                        uuid={this.state.uuid}
                    /> : null}
                {this.state.updatedRoundDuration}|
                {this.state.updatedRoundQuantity}
                {this.state.status === 'pregame' ?
                    <RoomSettings
                        uuid={this.state.uuid}
                        admin={this.state.admin}
                        startGame={this.startGame}
                        players={this.state.players}
                        strings={this.props.strings}
                        propagateFieldChange={this.propagateFieldChange}
                        updatedRoundQuantity={this.state.updatedRoundQuantity}
                        updatedRoundDuration={this.state.updatedRoundDuration}
                    />
                    :
                    null
                }

                <div class="extractPanel">
                    {(this.state.status === 'round' || this.state.status === 'roundEnd') ?

                        <Countdown
                            status={this.state.status}
                            timeRemaining={this.state.timeRemaining}
                            timePerRound={this.state.timePerRound}
                            answer={this.state.answer}
                            pageTitle={this.state.pageTitle}
                        /> : null
                    }
                    {(this.state.status === 'round' || this.state.status === 'roundEnd') ?

                        <Extract
                            status={this.state.status}
                            timeRemaining={this.state.timeRemaining}
                            timePerRound={this.state.timePerRound}
                            extract={extract}
                        /> : null
                    }
                </div>

                {(this.state.status === 'gameEnd') ?
                    <ScoreBoard
                        players={this.state.results}
                        admin={this.state.admin}
                        uuid={this.state.uuid}
                        restartGame={this.restartGame}
                    /> : null
                }
                <div id="bottomUI" onClick={(e) => this.clickBottomUI(e)}>
                    <MainInput
                        status={this.state.status}
                        playersWhoFound={this.state.playersWhoFound}
                        uuid={this.state.uuid}
                        submitChat={this.submitChat}
                        submitAnswer={this.submitAnswer}
                        strings={this.props.strings}
                    />

                    <Chat chat={this.state.chat} />
                </div>


                {this.state.foundAnimation === 1 ?
                    <WordFoundAnimation foundMessage={this.state.foundMessage} />
                    : null}
                {this.state.soundOn === 1 ?
                    <Sound
                        url={this.state.sound}
                        playStatus={Sound.status.PLAYING}
                        onFinishedPlaying={this.handleAudioFinishedPlaying.bind(this)}
                    /> : null}

                <Helmet>
                    <title>{webPageTitle}</title>
                </Helmet>
            </div>
        );
    }
}


export default withRouter(Room);
