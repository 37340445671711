import React, { Component } from "react";
import '../css/LocaleSelector.css';

class LocaleSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    changeLocale() { }


    render() {
        return (
            <div id="LocaleSelector">
                <select class="localeSelector box" onChange={(e) => this.props.changeLocale(e.target.value)}>
                    <option value="en" selected={this.props.strings['language_code'] === 'en' ? 'selected' : null}>English</option>
                    <option value="eo" selected={this.props.strings['language_code'] === 'eo' ? 'selected' : null}> Esperanto</option>
                    <option value="fr" selected={this.props.strings['language_code'] === 'fr' ? 'selected' : null}> Français</option>
                </select>
                <span class="focus"></span>
            </div>
        );
    }
}


export default LocaleSelector;
