import React, { Component } from "react";
import '../css/WordFoundAnimation.css';

import confetti from 'canvas-confetti';

class WordFoundAnimation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    componentDidMount() {
        var count = 200;
        var defaults = {
            origin: { y: 0.7 },
            zIndex: 10,
            colors: ['#CDE7B0', '#FE938C', '#B4ADEA']
        };

        function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
        }

        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        fire(0.2, {
            spread: 60,
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }
    render() {
        let word = this.props.foundMessage;
        return (
            <div class="wordFoundAnimCanvas">
                <div class="WordFoundAnimationWrapper">
                    <div class="WordFoundAnimation"><span>{word}</span></div>
                </div>
            </div>
        );
    }
}


export default WordFoundAnimation;
