import React, { Component } from "react";
import '../css/AdminPanel.css';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    render() {
        return (
            <div id="AdminLogin" className="box boxAdmin">
                <Formik
                    initialValues={{
                    }}

                    validationSchema={
                        Yup.object({
                            username: Yup.string().nullable()
                                .required(),
                            password: Yup.string().nullable()
                                .required()
                        })
                    }

                    onSubmit={async (values) => {
                        this.props.logIn(values);
                    }}
                >
                    <Form className="formJoinGame">
                        <Field autoFocus id="username" autofocus={true} name="username" placeholder={this.props.strings['admin_username']} className="inputUsername" /><br />
                        <Field id="password" name="password" type="password" placeholder={this.props.strings['admin_password']} className="inputUsername" /><br />
                        {/* <span className="formError"><ErrorMessage name="username" /></span> */}
                        <button type="submit" className="buttonCreateGame">{this.props.strings['admin_login']}</button>
                    </Form>


                </Formik>
            </div>
        );
    }
}


export default AdminLogin;
