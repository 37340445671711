import React, { Component } from "react";
import JoinGame from './JoinGame';
import { Redirect } from 'react-router-dom';

class Game extends Component {
    constructor(props) {
        super(props);
        this.createRoom = this.createRoom.bind(this);
        this.joinRoom = this.joinRoom.bind(this);
        this.roomNotFound = this.roomNotFound.bind(this);
        this.state = {
            roomId: '',
            redirect: null
        }
    }
    componentDidMount() {
        let serverUrl = 'http://localhost:5000/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:5000/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl });

        const storedName = window.localStorage.getItem('username');
        if (storedName) {
            this.setState({ username: storedName });
        }

    }
    roomNotFound() {
        this.setState({ roomId: null })
    }
    createRoom(values) {
        if (values.username === null) return;
        this.setState({ username: values.username });
        window.localStorage.setItem('username', values.username);
        this.requestRoomId(values.language);
    }
    requestRoomId(language) {
        const params = new URLSearchParams({ username: this.state.username, private: true, language: language })

        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'room/new?' + params, options)
            .then(res => res.json().then(json => {
                if (json.message === 'success') {
                    this.props.joinRoom(json.roomId, this.state.username);
                    this.setState({ redirect: '/r/' + json.roomId })

                }
            }))
            .catch(err => console.log(err));
    }



    joinRoom(values) {
        if (values.username === null) return;
        this.setState({ username: values.username });
        window.localStorage.setItem('username', values.username);
        this.requestRoomToJoin(values.language);

    }
    requestRoomToJoin(language) {
        const params = new URLSearchParams({ username: this.state.username, private: false, language: language })

        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'room/join?' + params, options)
            .then(res => res.json().then(json => {
                if (json.message === 'success') {
                    this.props.joinRoom(json.roomId, this.state.username);
                    this.setState({ redirect: '/r/' + json.roomId })
                }
            }))
            .catch(err => console.log(err));
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        return (
            <div id="Game">
                <JoinGame createRoom={this.createRoom} joinRoom={this.joinRoom} roomNotFound={this.roomNotFound} username={this.state.username} strings={this.props.strings} changeLocale={this.props.changeLocale} />

            </div>
        );
    }
}


export default Game;
