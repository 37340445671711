import React, { Component } from "react";
import '../css/PlayerList.css';


class PlayerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    render() {

        let playerList = [];
        for (let element in this.props.players) {
            let player = this.props.players[element];
            player.id = Math.round(Math.random() * 1000000);

            if (!player.score) player.score = 0;

            if (this.props.playersWhoFound.includes(element)) {
                player.found = true;
            } else { player.found = false }

            if (element === this.props.admin) {
                player.admin = true;
            } else { player.admin = false }

            if (element === this.props.uuid) {
                player.you = true;
            } else { player.you = false }

            playerList.push(player)
        }

        return (
            <div id="PlayerList" className='box'>
                {typeof playerList === 'string' ?
                    playerList : playerList.map(item => (
                        <li key={item.id} class={item.found ? 'list_UserFound' : 'list_User'}><span class={item.found ? 'list_TooltipHidden' : 'list_TooltipHidden'} id={item.uuid}>ge</span><span class={item.you ? 'list_Username list_You' : 'list_Username'}>{item.username}</span><span class="list_Score">{item.score}</span></li>
                    ))}
            </div>
        );
    }
}


export default PlayerList;
