import React, { Component } from "react";
import '../css/AdminPanel.css';
import AdminStatsPanelItem from './AdminStatsPanelItem';

class AdminStatsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    render() {
        return (
            <div id="AdminStatsPanel" class="statsPanel">
                {this.props.stats.map(item =>
                    <AdminStatsPanelItem item={item} />
                )}
            </div>
        );
    }
}


export default AdminStatsPanel;
