import React, { Component } from "react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import '../css/RoomSettings.css';


class RoomSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roundQuantity: 10,
            roundDuration: 60
        }

    }
    linkShareFocus(event) {
        event.target.select();
        document.execCommand('copy');
    }
    componentDidUpdate(oldProps) {

        // When admin changes the settings, display updated settings to other clients.
        if (this.props.updatedRoundQuantity !== oldProps.updatedRoundQuantity) {
            document.getElementById('roundQuantity').value = 1;
            this.setState({ roundQuantity: this.props.updatedRoundQuantity });
        }
        if (this.props.updatedRoundDuration !== oldProps.updatedRoundDuration) {
            document.getElementById('roundQuantity').value = parseInt(this.props.updatedRoundQuantity);
            this.setState({ roundDuration: this.props.updatedRoundDuration });
        }
        document.getElementById('roundQuantity').value = this.state.roundQuantity;
        document.getElementById('roundDuration').value = this.state.roundDuration;
    }
    render() {
        let adminName = '';
        for (let element in this.props.players) {
            if (element === this.props.admin) {
                adminName = this.props.players[element].username;
            }
        }
        let link = window.location.href;

        return (
            <div id="RoomSettings">
                <div className='roomSettings box'>
                    {this.props.uuid !== this.props.admin ?
                        <span class="settingsMessage">{adminName} {this.props.strings['settings_setsGame']}</span>
                        : null}

                    <Formik
                        initialValues={{
                            roundQuantity: this.state.roundQuantity,
                            roundDuration: this.state.roundDuration,
                        }}

                        validationSchema={
                            Yup.object({
                                roundQuantity: Yup.number()
                                    .min(5)
                                    .max(30)
                                    .integer()
                                    .required('Required'),
                                roundDuration: Yup.number()
                                    .min(20)
                                    .max(200)
                                    .integer()
                                    .required('Required'),
                            })
                        }


                        onSubmit={async (values) => {
                            this.props.startGame(values);
                        }}
                    >{({ setFieldValue, handleSubmit, values }) => (
                        <Form className="formRoomSettings">


                            <label htmlFor="roundQuantity" className={this.props.uuid !== this.props.admin ? 'settingsDisabled' : null}>{this.props.strings['settings_rounds']} <span class="settingValue">{this.props.uuid !== this.props.admin ? this.state.roundQuantity : values.roundQuantity}</span></label><br />
                            <Field
                                type="range"
                                id="roundQuantity"
                                name="roundQuantity"
                                className="rangeInput"
                                min="5"
                                max="30"
                                step="5"
                                disabled={this.props.uuid !== this.props.admin ? 'disabled' : null}
                                onInput={(e) => { this.props.propagateFieldChange(e.target.id, e.target.value); }}
                            /><br />

                            <label htmlFor="roundDuration" className={this.props.uuid !== this.props.admin ? 'settingsDisabled' : null}>{this.props.strings['settings_roundDuration']} <span class="settingValue">{this.props.uuid !== this.props.admin ? this.state.roundDuration : values.roundDuration} {this.props.strings['settings_seconds']}</span></label><br />
                            <Field
                                type="range"
                                id="roundDuration"
                                name="roundDuration"
                                className="rangeInput"
                                min="20"
                                max="200"
                                step="10"
                                disabled={this.props.uuid !== this.props.admin ? 'disabled' : null}
                                onInput={(e) => { this.props.propagateFieldChange(e.target.id, e.target.value); }}
                            /><br />

                            <button type="submit" className="buttonJoinGame" disabled={this.props.uuid !== this.props.admin ? 'disabled' : null}>{this.props.strings['settings_startGame']}</button>

                        </Form>
                    )}

                    </Formik>
                </div>
                <div class="box linkShareBox"><span class="linkSharePrompt">{this.props.strings['settings_shareLink']}</span><br />
                    <input type="text" value={link} class="inputUsername" onFocus={this.linkShareFocus} /></div>
            </div>
        );
    }
}


export default RoomSettings;
