import io from 'socket.io-client';

let socket;


export const initiateSocket = (room, username, uuid) => {

    let serverUrl = 'http://localhost:5000/'
    if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:5000/'
    else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'

    console.log(serverUrl)

    socket = io(serverUrl);
    // socket = io('http://localhost:4200');
    console.log(`Connecting socket...`);
    if (socket && room) {
        socket.emit('join', room, username, uuid);
        // console.log(socket, room)
    }

    socket.on('ping', msg => {
        // console.log('Websocket event received!');
        console.log(msg);
        // return cb(null, msg);
    });
    socket.on('connect_error', (error) => {
        console.log(error)
    });
    socket.on('disconnect', (reason) => {
        console.log('reason ' + reason)
        socket.emit('join', room, username, uuid);
    });
}
export const disconnectSocket = (room, username) => {
    console.log('Disconnecting socket...');
    if (socket && room) {
        console.log(room)
        socket.emit('disconnect', room);
        socket.disconnect();

    }
}
export const subscribeToChat = (cb) => {
    if (!socket) return (true);
    socket.on('chat', msg => {
        // console.log('Websocket event received! chat');
        return cb(null, msg);
    });
}
export const sendMessage = (room, message, username) => {
    if (socket) socket.emit('chat', { message, room, username });
}


export const subscribeToSettings = (cb) => {
    if (!socket) return (true);
    socket.on('setting', msg => {
        return cb(null, msg);
    });
}
export const updateSettings = (room, field, value, uuid) => {
    if (socket) socket.emit('setting', { field, value, room, uuid });
}

export const sendAnswer = (room, answer, uuid) => {
    if (socket) socket.emit('answer', { room, answer, uuid });
}
export const subscribeToGame = (cb) => {
    if (!socket) return (true);
    socket.on('game', msg => {
        return cb(null, msg);
    });
}



export const requestGameStart = (room, options, uuid) => {
    if (!socket) return (true);
    socket.emit('startGame', { room, options, uuid })
}
export const requestGameReset = (room, uuid) => {
    if (!socket) return (true);
    socket.emit('resetGame', room, uuid)
}
// export const sendAnswer = (room, message) => {
//     if (socket) socket.emit('game', { message, room });
// }